<template>
  <div>
      <Desplegable v-model="prueba" :datos="[{id:1,label:'Lucas'},{id:2,label:'Samuel'},{id:3,label:'Lucía'},{id:4,label:'Patacos'}]" />
      <button @click="añadir=true">Nuevo</button>
      <form v-if="deleteuser" @submit.prevent="eliminar">
          <label>¿Está seguro de que quiere eliminar el usuario?</label>
          <input type="password" v-model="nuevo.password">
          <input type="submit" value="Aceptar">
      </form>
      <form @submit.prevent="nuevousuario" v-if="añadir">
          <input type="text" placeholder="Usuario" v-model="nuevo.user">
          <input type="password" placeholder="Contraseña" v-model="nuevo.password">
          <input type="submit" value="Guardar">        
      </form>
      <form @submit.prevent="cambiarpass" v-if="cambiar">
          <input type="password" placeholder="Contraseña" v-model="newpass">
          <input type="submit" value="Guardar">        
      </form>
      <table v-if="usuarios">
          <tr>
              <th>#</th>
              <th>User</th>
              <th>Rol</th>
              <th>Actions</th>
          </tr>
          <tr v-for="(usuario,index) in usuarios" :key="index">
              <td>{{usuario.id}}</td>
              <td>{{usuario.user}}</td>
              <td>Rol</td>
              <td>
                <button @click="deleteuser = true;ideliminar = usuario.id">Eliminar</button>
                <button @click="cambiar = true;idcambiar = usuario.id">Cambiar Contraseña</button>
              </td>
          </tr>
      </table>
  </div>
</template>

<script>
import Desplegable from '@/components/Desplegable.vue'
import qs from 'qs'
import axios from 'axios'
import router from '@/router/index.js'
import { mapActions, mapState } from 'vuex'
export default {
    name: 'Usuarios',
    data: () => {
        return {
            usuarios: null,
            añadir: false,
            cambiar: false,
            deleteuser: false,
            nuevo: {
                user: null,
                password: null
            },
            ideliminar: null,
            idcambiar: null,
            newpass: null,
            prueba: null
        }
    },
    components:{
        Desplegable
    },
    computed:{
        ...mapState(['cache'])
    },
    methods:{
        ...mapActions(['api']),
        async cambiarpass(){
            var data = qs.stringify({
                'user': this.cache.user,
                'password': this.newpass,
                'id': this.idcambiar,
                tipo: 'cambiarpass'
            });
            var config = {
                method: 'post',
                url: 'https://centrocatalan.synology.me/php2/backs.php',
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                    data : data
            };
            await axios(config)
            .then(function (response) {
                if(typeof response.data === 'object'){
                    alert(response.data.error)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
            // router.go()
        },
        async eliminar(){
            var data = qs.stringify({
                'id': this.ideliminar,
                'user': this.cache.user,
                'password': this.nuevo.password,
                tipo:'eliminar'
            });
            var config = {
                method: 'post',
                url: 'https://centrocatalan.synology.me/php2/backs.php',
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                    data : data
            };
            await axios(config)
            .then(function (response) {
                if(typeof response.data === 'object'){
                    alert(response.data.error)
                }else{
                    console.log(response)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
            // router.go()
        },
        async nuevousuario(){
            var data = qs.stringify({
                'user': this.nuevo.user,
                'password': this.nuevo.password,
                tipo: 'register'
            });
            var config = {
                method: 'post',
                url: 'https://www.centrocatalan.synology.me/php2/backs.php',
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                    data : data
            };
            await axios(config)
            .then(function (response) {
                if(typeof response.data === 'object'){
                    alert(response.data.status)
                    router.go()
                }
            })
            .catch(function (error) {
                console.log(error);
            });
            this.nuevo.user = null
            this.nuevo.password = null
            this.añadir = false
        }
    },
    async beforeMount(){
        this.usuarios = await this.api(['usuarios'])
    }
}
</script>

<style>

</style>