<template>
  <div>
      <h3>{{tabla.toUpperCase()}}</h3>
      <table v-if="listas.campos[tabla]" :key="key">
          <tr>
              <th>#</th>
              <th>Nombre</th>
              <th>Título</th>
              <th>Ancho</th>
              <th>Visble</th>
              <th>Posición</th>
          </tr>
          <tr v-for="(item,index) in preferencias[tabla]" :key="index">
              <td>{{index+1}}</td>
              <td>{{listas.campos[tabla][item].nombre}}</td>
              <td><input type="text" v-model="listas.campos[tabla][item].titulo" @change="cambios(item)"></td>
              <td><input type="number" v-model="listas.campos[tabla][item].ancho" @change="cambios(item)"></td>
              <td><input type="checkbox" v-model="listas.campos[tabla][item].visible" @change="cambios(item)"></td>
              <td>
                  <button @click="subir(index-1)">Subir</button>
                  <button @click="subir(index)">Bajar</button>
              </td>
          </tr>
      </table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    name: 'Preferencias',
    data: () => {
        return {
            key: 1
        }
    },
    computed:{
        ...mapState(['listas','preferencias','cache'])
    },
    methods: {
        ...mapActions(['api']),
        cambios(indice){
            this.listas.campos[this.tabla][indice].ancho = parseInt(this.listas.campos[this.tabla][indice].ancho)
            this.api([{'campo':'tipo','valor':'guardar'},{'campo':'USUARIO','valor':this.cache.user},{'campo':'TABLA','valor':this.tabla.toUpperCase()},{'campo':'INDICE','valor':indice},{'campo':'DATOS','valor':JSON.stringify(this.listas.campos[this.tabla][indice])},'preferencias'])
        },
        subir(i){
            const viejo = this.preferencias[this.tabla]
            const pre = viejo.slice(0,i)
            const post = viejo.slice(i+2,viejo.length)
            this.preferencias[this.tabla] = pre.concat(viejo[i+1],viejo[i],post)
            this.key++
            this.api([{'campo':'tipo','valor':'guardar'},{'campo':'USUARIO','valor':this.cache.user},{'campo':'TABLA','valor':this.tabla.toUpperCase()},{'campo':'ORDEN','valor':JSON.stringify(this.preferencias[this.tabla])},'preferencias'])
        }
    },
    props:{
        tabla: String
    },
    async beforeMount(){
        let res = await this.api([{'campo':'TABLA','valor':this.tabla.toUpperCase()},{'campo':'USUARIO','valor':this.cache.user},{'campo':'tipo','valor':'pedir'},'preferencias'])
        this.preferencias[this.tabla] = JSON.parse(res.orden[0]['DATOS'])
        this.listas.campos[this.tabla] = Object.keys(res.campos).map(function(x){
            return JSON.parse(res.campos[x]['DATOS'])
        })
        this.preferencias.registros[this.tabla] = res.registros[0]['DATOS']
        this.key++
    }
}
</script>

<style scoped>
*{
    text-align: left;
}
div{
    display: block;
    align-content: center;
}
h3{
    margin-left: 20%;
}
table{
    margin-left: 20%;
}

</style>