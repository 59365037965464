<template>
  <div>
      <Preferencias tabla='presupuestos'/>
      <Preferencias tabla='empresas'/>
      <Preferencias tabla='contactos'/>
      <Preferencias tabla='facturas_geo'/>
      <Preferencias tabla='obras'/>
  </div>
</template>

<script>
import Preferencias  from '@/components/Preferencias.vue'
export default {
    components: {
      Preferencias
    }
}
</script>

<style>

</style>